export const DELETE_DIALOG_DISPLAY_REQUESTED: string = 'DELETE_DIALOG_DISPLAY_REQUESTED'
export const DELETE_FAVORITE_REQUESTED: string = 'DELETE_FAVORITE_REQUESTED'
export const DELETE_CAPTURE_REQUESTED: string = 'DELETE_CAPTURE_REQUESTED'
export const CAPTURE_REVIEW_DIALOG_DISPLAY_REQUESTED: string = 'CAPTURE_REVIEW_DIALOG_DISPLAY_REQUESTED'
export const CAPTURE_FLAG_DIALOG_DISPLAY_REQUESTED: string = 'CAPTURE_FLAG_DIALOG_DISPLAY_REQUESTED'

export const LOCALE_DIALOG_DISPLAY_REQUESTED: string = 'LOCALE_DIALOG_DISPLAY_REQUESTED'
export const DOMAIN_DIALOG_DISPLAY_REQUESTED: string = 'DOMAIN_DIALOG_DISPLAY_REQUESTED'

export const MEDIA_PACK_SHIFT_REQUESTED: string = 'MEDIA_PACK_SHIFT_REQUESTED'
export const MEDIA_PACK_LAYOUT_REQUESTED: string = 'MEDIA_PACK_LAYOUT_REQUESTED'
export const MEDIA_CARD_DIRECTORIES_OPENED: string = 'MEDIA_CARD_DIRECTORIES_OPENED'

export const TEACHER_ACCOUNT_REQUIRED_DISPLAY_REQUESTED: string = 'TEACHER_ACCOUNT_REQUIRED_DISPLAY_REQUESTED'

export const PAGE_FADER_SHOW_REQUESTED: string = 'PAGE_FADER_SHOW_REQUESTED'
export const PAGE_FADER_HIDE_REQUESTED: string = 'PAGE_FADER_HIDE_REQUESTED'

export const SHOWCASE_VIDEO_SRC_SET: string = 'SHOWCASE_VIDEO_SRC_SET'

export const DIRECTORY_SLIDESHOW_CONFIG_DIALOG_REQUESTED: string = 'DIRECTORY_SLIDESHOW_CONFIG_DIALOG_REQUESTED'
export const DIRECTORY_SLIDESHOW_DIALOG_REQUESTED: string = 'DIRECTORY_SLIDESHOW_DIALOG_REQUESTED'
export const DIRECTORY_SLIDESHOW_DIALOG_CLOSED: string = 'DIRECTORY_SLIDESHOW_DIALOG_CLOSED'