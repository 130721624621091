import {useUserStore} from '~/stores/user'

const {getSession} = useSession()

export default defineNuxtPlugin((nuxtApp) => {
  // If no one is authenticated yet, we load the user's session (if any)
  // and reload the page if we got something
  // Note: this only happens once, on the client side, when the app has been mounted
  // If WILL happen again if the page is reloaded by the user (or visited again later)
  nuxtApp.hook('app:beforeMount', () => {
    if (!useUserStore().isAuthenticated) {

      const requestPostData = useState('requestPostData')

      getSession(useRoute().query, toRaw(requestPostData.value)).then(async (redirectData) => {
        // If the user's API endpoint asked for a redirect, we honor it
        if (redirectData && redirectData.redirect) {
          return window.location.href = redirectData.redirect
        }

        // If the user is logged in, and we're on the login page...
        if (useNuxtApp().$getRouteBaseName() === 'login') {
          // We go to the requested page...
          if (useRoute().query.next) {
            return navigateTo(String(useRoute().query.next))
          }

          // Or to the home page
          return navigateTo(useNuxtApp().$localePath('index'))
        }

        // If we're on a public page, we have to reload it (but we remove query params)
        return window.location.href = window.location.href.split('?')[0]
      }).catch(() => {
        // console.warn('No session')
      })
    }
  })
})
