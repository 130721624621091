// @ts-ignore
import {config} from "platformsh-config/lib/platformsh"
import * as Sentry from "@sentry/vue"

export default defineNuxtPlugin((nuxtApp) => {

    if (process.env.NODE_ENV !== "production") {
        return
    }

    let platformConfig = null
    try {
        platformConfig = config()
    } catch (e) {}

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: "https://09f1bc80bb2840369970e51fb3ebd825@o4504870134415360.ingest.sentry.io/4504870404489216", // TODO: Extract?
        integrations: [
            new Sentry.BrowserTracing({
                routingInstrumentation: Sentry.vueRouterInstrumentation(useRouter()),
            }),
            new Sentry.Replay(),
        ],
        tracesSampleRate: platformConfig ? (platformConfig.onProduction() ? .01 : 1) : 0,

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.01,
        replaysOnErrorSampleRate: 1.0,

        ignoreErrors: [
            'ResizeObserver loop limit exceeded',
            'ResizeObserver loop completed with undelivered notifications.',
        ],
    })
})
