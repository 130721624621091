import { defineNuxtPlugin } from '#app'
import FloatingVue from 'floating-vue'
import 'floating-vue/dist/style.css'
import './floating-vue/style.css'

export default defineNuxtPlugin(({ vueApp }) => {
    FloatingVue.options.distance = 10
    FloatingVue.options.overflowPadding = 10
    FloatingVue.options.themes.tooltip.delay.show = 500
    vueApp.use(FloatingVue)
})