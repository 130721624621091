<script setup lang="ts">
import {TEACHER_ACCOUNT_REQUIRED_DISPLAY_REQUESTED} from "~/types/bus-event-names"
import Dialog from "~/components/molecules/Dialog.vue"
import {useUserStore} from "~/stores/user"

const userStore = useUserStore()
const dialog = ref<typeof Dialog|null>(null)

useBusEvent(TEACHER_ACCOUNT_REQUIRED_DISPLAY_REQUESTED, openDialog)

function openDialog() {
  dialog.value?.show()
}

</script>

<template>
  <MoleculesDialog ref="dialog">
    <div class="bg-white rounded-bento p-4">
      <AtomsHeadingsLarge tag="h1" class="mb-5" v-if="userStore.userData?.institution">{{ userStore.userData.institution?.name }}</AtomsHeadingsLarge>
      <p class="max-w-prose mb-4">
        {{ $t('teachers_area.account_required_intro') }}
        <NuxtLink class="underline" :to="localePath('teachers-area')" @click="dialog?.close()">{{ $t('_global.learn_more') }}</NuxtLink>.
      </p>
      <div class="flex gap-2 flex-wrap">
        <AtomsButton class="grow text-center" :label="$t('_global.create')" :to="localePath('me-hello')" @click="dialog?.close()" dark-color="domain" />
        <AtomsLoginLink class="grow" @click="dialog?.close()" />
      </div>
    </div>
  </MoleculesDialog>
</template>
