import {useUserStore} from '~/stores/user'
import {LayoutKey} from '#build/types/layouts'

type LayoutProps = {
  layout: LayoutKey,
  userIsAnonymousStudent: boolean,
}

const defaultLayoutProps: LayoutProps = {
  layout: 'default',
  userIsAnonymousStudent: false,
}

const minimalLayoutProps: LayoutProps = {
  layout: 'minimal',
  userIsAnonymousStudent: true,
}

const useStudentPageMeta = computed<LayoutProps>(() => {
  if (useUserStore().isAuthenticated) {
    return defaultLayoutProps
  }

  const route = useRoute()

  if (route.name && (route.name.toString().startsWith('media-id-slug') || route.name.toString().startsWith('capture-code'))) {
    return (route.query.dir || route.query.auth) ? minimalLayoutProps : defaultLayoutProps
  }

  return {
    layout: 'minimal',
    userIsAnonymousStudent: true,
  }

})

export const useStudentPageLayout = computed<LayoutKey>(() => {
  return useStudentPageMeta.value.layout
})

export const userIsAnonymousStudentOnStudentPage = computed<boolean>(() => {
  return useStudentPageMeta.value.userIsAnonymousStudent
})