import {defineStore} from "pinia"
import type {DomainData} from '~/types/DomainData'
import type {PageData} from '~/types/PageData'

export const useDomainDataStore = defineStore('domain', () => {
    const _nuxtBaseUrl = ref<string|null>(null)
    const _symfonyBaseUrl = ref<string|null>(null)
    const _apiBaseUrl = ref<string|null>(null)
    const _serverToServerApiBaseUrl = ref<string|null>(null)

    const domainData = ref<DomainData>({
        domain: '<uninitialized>',
        tree: {id: 0, name: '<uninitialized-root>', i18n: {}},
        pageGroups: [],
        publicPages: {},
        contactSubjects: [],
        countries: {},
        statesPerCountry: {},
        company: {},
        showcase: {
            intro: {
                media: [],
            },
            wall: {
                media: [],
            },
            browser: {},
        },
    })

    const domain = computed(() => {
        return domainData.value.domain
    })

    const isPrimary = computed(() => {
        return domain.value === 'junior'
    })

    const isSecondary = computed(() => {
        return domain.value === 'www'
    })

    function initBaseUrls(nuxt: string, symfony: string, api: string, serverToServerApiBaseUrl: string) {
        // If both apps are hosted on the same domain, API calls can be made without the domain prefix (useful for proxies)
        if (nuxt === symfony) {
            api = api.replace(symfony, '')
        }

        _nuxtBaseUrl.value = nuxt
        _symfonyBaseUrl.value = symfony
        _apiBaseUrl.value = api
        _serverToServerApiBaseUrl.value = serverToServerApiBaseUrl
    }

    const getDomainHome = function(_domain:string|null = null, locale: string|null = null) {
        if (!_domain) {
            _domain = domainData.value.domain
        }

        let base = _nuxtBaseUrl.value?.replace('{domain}', _domain) ?? '[nuxt-base-url-is-null]'

        return locale ? `${base}/${locale}` : base
    }

    const getDomainLabel = function (domain: string) {
        return domain === 'junior' ? useNuxtApp().$i18n.t('purchase.catalog.junior') : useNuxtApp().$i18n.t('purchase.catalog.www')
    }

    const symfonyBaseUrl = computed(() => {
        return _symfonyBaseUrl.value?.replace('{domain}', domain.value)
    })

    const apiPrefix = computed(() => {
        if (!_apiBaseUrl.value) {
            throw new Error('API base URL is not initialized')
        }

        return _apiBaseUrl.value.replace('{domain}', domain.value)
    })

    const serverToServerApiPrefix = computed(() => {
        if (!_serverToServerApiBaseUrl.value) {
            throw new Error('Server-to-server API base URL is not initialized')
        }

        return _serverToServerApiBaseUrl.value.replace('{domain}', domain.value)
    })

    const wwwBaseUrl = computed(() => {
        return _nuxtBaseUrl.value?.replace('{domain}', 'www')
    })

    const domainClass = computed(() => {
        return 'domain-' + domain.value
    })

    const countryOptions = computed(() => {
        return Object.entries(domainData.value.countries).map(([value, label]) => {
            return { label, value }
        })
    })

    const countryNeedsState = (country: string) => {
        return Object.keys(domainData.value.statesPerCountry).includes(country)
    }

    const getStatesForCountryOptions = (pickedCountry: string) => {
        if (!pickedCountry) {
            return null
        }

        if (!countryNeedsState(pickedCountry)) {
            return null
        }

        const options = []
        const states = domainData.value.statesPerCountry[pickedCountry]
        for (const [value, label] of Object.entries(states)) {
            options.push({ label, value })
        }

        options.sort((a, b) => a.label.localeCompare(b.label))

        return options
    }

    function getCountryStateLabel(country: string, state: string) {
        const states = domainData.value.statesPerCountry[country]
        if (!states) {
            return null
        }

        return states[state] ?? null
    }

    function getPublicPageWithEnglishSlug(slug: string): PageData|null {
        return domainData.value.publicPages[slug] ?? null
    }

    const otherDomain = computed(() => {
        return isPrimary.value ? 'www' : 'junior'
    })

    return {
        initBaseUrls,
        getDomainHome,
        getDomainLabel,

        symfonyBaseUrl,
        apiPrefix,
        serverToServerApiPrefix,

        // These properties have to be there in order to keep the state on the client side
        _nuxtBaseUrl,
        _symfonyBaseUrl,
        _apiBaseUrl,
        wwwBaseUrl,

        domainData,
        getPublicPageWithEnglishSlug,

        countryOptions,
        countryNeedsState,
        getStatesForCountryOptions,
        getCountryStateLabel,
        isPrimary,
        isSecondary,
        domainClass,

        otherDomain,
    }
})