import validate from "/app/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/app/middleware/01.auth.global.ts";
import _02_45public_45access_45global from "/app/middleware/02.public-access.global.ts";
import close_45search_45suggestions_45global from "/app/middleware/close-search-suggestions.global.ts";
import directory_45global from "/app/middleware/directory.global.ts";
import page_45events_45global from "/app/middleware/page-events.global.ts";
import search_45clear_45global from "/app/middleware/search-clear.global.ts";
import store_45page_45locale_45switch_45data_45global from "/app/middleware/store-page-locale-switch-data.global.ts";
import manifest_45route_45rule from "/app/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _02_45public_45access_45global,
  close_45search_45suggestions_45global,
  directory_45global,
  page_45events_45global,
  search_45clear_45global,
  store_45page_45locale_45switch_45data_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "create-account": () => import("/app/middleware/create-account.ts"),
  directories: () => import("/app/middleware/directories.ts"),
  "index-page": () => import("/app/middleware/index-page.ts"),
  "media-details": () => import("/app/middleware/media-details.ts"),
  "node-details": () => import("/app/middleware/node-details.ts"),
  prices: () => import("/app/middleware/prices.ts"),
  "refresh-geo-data": () => import("/app/middleware/refresh-geo-data.ts"),
  search: () => import("/app/middleware/search.ts"),
  "student-page": () => import("/app/middleware/student-page.ts")
}