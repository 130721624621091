<script setup lang="ts">
const props = defineProps({
  error: Object
})

const handleError = () => clearError({redirect: useNuxtApp().$localePath({name: 'index'})})
</script>

<template>
  <NuxtLayout name="login">
    <div class="text-center max-w-prose">
      <h1 class="inline-block relative mb-6 mx-auto">
        <img src="/assets/images/error.svg" width="370" height="225" alt="" />
        <span class="block text-center sm:absolute mt-4 sm:mt-0 top-[14%] right-[9%] sm:w-[16%] text-orange font-bold text-[190%]">
          {{ error.statusCode }}
        </span>
      </h1>
      <div>
        <p class="opacity-50">{{ error.message }}</p>
        <AtomsButton class="mt-4" :label="$t('_global.back')" @click="handleError" />
      </div>
    </div>
  </NuxtLayout>
</template>