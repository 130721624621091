<script setup lang="ts">
import {computed} from "vue"
import {NuxtLink} from "#components"

interface Props {
  label?: string,

  size?: 'mini' | 'small' | 'medium' | 'large' | 'fluid' | 'inherit',

  darkColor?: string|null,
  lightColor?: string|null,

  hoverScheme?: 'default' | 'border' | 'none' | 'default-inverted',
  hoverTextColor?: string,

  beforeIcon?: string|null,
  afterIcon?: string|null,

  tag?: string,
  to?: string,
  href?: string,

  disabled?: boolean,

  iconClass?: string|string[],
  beforeIconClass?: string|string[],
  afterIconClass?: string|string[],

  noLabelOnSmallScreen?: boolean,
  allowHtmlLabel?: boolean,
}

const props = withDefaults(defineProps<Props>(), {
  size: 'medium',
  darkColor: 'domain-dark',
  lightColor: 'white',
  hoverScheme: 'default',
  disabled: false,
  noLabelOnSmallScreen: false,
})

const root = ref<HTMLElement|typeof NuxtLink|null>(null)

const rootClasses = computed(() => {

  let textSize = 'text-button'

  if (props.size === 'large') {
    textSize = 'text-button-large'
  } else if (props.size === 'small') {
    textSize = 'text-button-small'
  } else if (props.size === 'mini') {
    textSize = 'text-button-mini'
  } else if (props.size === 'fluid') {
    textSize = 'text-button-small lg:text-button'
  } else if (props.size === 'inherit') {
    textSize = ''
  }

  let colors = [
    // Border
    props.darkColor, props.darkColor,
    // Background
    props.darkColor, props.lightColor,
    // Text
    props.lightColor, props.darkColor,
  ]

  if (props.hoverScheme === 'border') {
    colors = [
      // Border
      props.lightColor, props.darkColor,
      // Background
      props.lightColor, props.lightColor,
      // Text
      props.darkColor, props.darkColor,
    ]
  } else if (props.hoverScheme === 'none') {
    colors = [
      // Border
      props.lightColor, props.lightColor,
      // Background
      props.lightColor, props.lightColor,
      // Text
      props.darkColor, props.darkColor,
    ]
  } else if (props.hoverScheme === 'default-inverted') {
    colors = [
      // Border
      props.darkColor, props.lightColor,
      // Background
      props.lightColor, props.darkColor,
      // Text
      props.darkColor, props.lightColor,
    ]
  }

  if (props.hoverTextColor) {
    colors[5] = props.hoverTextColor
  }

  let padding = props.size === 'mini' ? 'px-[4px] py-[3px]' : 'px-[15px] py-[10px]'
  let disabledClasses = props.disabled ? 'pointer-events-none opacity-50' : ''

  return [
    textSize,

    `border-${colors[0]}`,
    `hover:border-${colors[1]}`,

    `bg-${colors[2]}`,
    `hover:bg-${colors[3]}`,

    padding,

    `text-${colors[4]}`,
    `hover:text-${colors[5]}`,

    disabledClasses
  ]
})

const iconSize = computed(() => {
  let textSize = 'text-button'

  if (props.size === 'large') {
    textSize = 'text-button-large'
  } else if (props.size === 'small') {
    textSize = 'text-button-small'
  } else if (props.size === 'mini') {
    textSize = 'text-button-mini'
  } else if (props.size === 'fluid') {
    textSize = 'text-button-small lg:text-button'
  }

  return textSize
})

const computedTag = computed(() => {
  return props.tag ? props.tag : (props.to ? NuxtLink : 'a')
})

function onEnterDown(e: Event) {
  if (computedTag.value === 'a') {
    e.preventDefault()
    root.value?.dispatchEvent(new Event('click', { 'bubbles': true }))
  }
}

const labelClass = computed(() => {
  return props.noLabelOnSmallScreen ? 'hidden md:block' : ''
})
</script>

<template>
  <component :is="computedTag" :to="to" :href="href"
             class="inline-flex justify-center cursor-pointer items-center gap-[.4em] min-w-button border-bento rounded-bento select-none outline-none focus-visible:ring-2 focus-visible:ring-domain transition"
             :class="rootClasses"
             :disabled="computedTag === 'button' ? disabled : null"
             tabindex="0"
             @keydown.enter="onEnterDown($event)" ref="root">
    <slot name="body">
      <span v-if="beforeIcon" class="material-icons-outlined mb-text-icon" :class="[iconSize, iconClass, beforeIconClass]">{{ beforeIcon }}</span>
      <template v-if="label">
        <span v-if="allowHtmlLabel" v-html="label" :class="labelClass" />
        <span v-else :class="labelClass">{{ label }}</span>
      </template>
      <span v-if="afterIcon" class="material-icons-outlined mb-text-icon" :class="[iconSize, iconClass, afterIconClass]">{{ afterIcon }}</span>
    </slot>
  </component>
</template>