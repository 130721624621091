function refsToFormData(props: any) {
  const fd = new FormData()

  Object.keys(props).forEach((key: string) => {
    fd.append(key, props[key].value)
  })

  return fd
}

function objectToFormData(props: any) {
  const fd = new FormData()

  Object.keys(props).forEach((key: string) => {
    if (props[key]) {
      fd.append(key, props[key])
    }
  })

  return fd
}

export const useFormData = () => {
  return {
    refsToFormData,
    objectToFormData,
  }
}
