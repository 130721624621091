import {useUserStore} from '~/stores/user'

export default defineNuxtRouteMiddleware(async (to, from) => {

    // Authenticated users can see all pages
    if (useUserStore().isAuthenticated) {
        return
    }

    // Redirect to login page for private pages accessed
    if (!routeIsAwaitingLocalizedRedirection() && !to.meta.publicAccess) {
        return navigateTo(useNuxtApp().$localePath({name: 'login', query: {next: to.path, ...to.query}}))
    }
})
