<script setup lang="ts">
import {useDomainDataStore} from "~/stores/domain"

const props = defineProps<{
  noCloseButton?: null|boolean,
}>()

const emit = defineEmits<{
  (e: 'nativeClose'): void
}>()

const {domainData} = useDomainDataStore()
const dialog = ref<HTMLDialogElement | null>(null)

const visible = ref(false)

watch(visible, (value) => {
  if (value) {
    dialog.value?.showModal()
  } else {
    setTimeout(() => {
      dialog.value?.close()
    }, 500)
  }
})

function show() {
  visible.value = true
}

function close() {
  emit('nativeClose')
  visible.value = false
}

defineExpose({
  show,
  close,
})

function closeOnBackdropClick(e: MouseEvent) {
  if (e.target === dialog.value) {
    close()
  }
}
</script>

<template>
  <dialog ref="dialog" class="bg-transparent p-2" :class="[visible ? 'is-visible' : 'is-hidden']" @click="closeOnBackdropClick" @close="close">
    <div class="transition duration-500" :class="[visible ? 'opacity-100' : 'opacity-0']">
      <p v-if="!noCloseButton" class="text-right text-white">
        <AtomsButton size="small" :label="$t('_global.close')" after-icon="close" tag="button" @click="close" class="mb-bento" hover-scheme="none" hover-text-color="domain" />
      </p>
      <div class="rounded-bento">
        <slot />
      </div>
    </div>
  </dialog>
</template>

<style scoped>
dialog::backdrop {
  background: rgba(0, 0, 0, .9);
  transition: opacity .5s;
  opacity: 0;
}
dialog.is-visible::backdrop {
  opacity: 1;
}
</style>