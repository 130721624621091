import {useCurrentPageRouteI18nStore} from "~/stores/current-page-route-i18n"
import {storeToRefs} from "pinia"
import {LocaleObject} from "vue-i18n-routing"

function isLocaleObject(o: string|LocaleObject): o is LocaleObject {
    return typeof o !== 'string'
}

export default defineNuxtRouteMiddleware((to, from) => {
    const { baseName, params, i18nParams } = storeToRefs(useCurrentPageRouteI18nStore())
    const locales = useNuxtApp().$i18n.locales.value

    baseName.value = useNuxtApp().$getRouteBaseName() ?? null // In reality, it should never be null
    params.value = to.params

    if (to.matched.length && to.matched[0].path.indexOf(':') === -1) {
        // If no param is needed, we prefill with empty locale objects
        const p:{[key: string]: any} = {}

        for (let locale of locales) {
            p[isLocaleObject(locale) ? locale.code : locale] = {}
        }

        i18nParams.value = p
    } else {
        // This will be the stores/pages responsibility to update this value (using `storeRouteI18nData`)
        i18nParams.value = {}
    }

})
