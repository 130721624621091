import {useDirectoryStore} from "~/stores/directory";

export default defineNuxtRouteMiddleware(async (to, from) => {
    const directoryStore = useDirectoryStore()

    const directoryCode = getSingleValue(to.query.dir)

    if (directoryCode) {
        await directoryStore.getDirectory(directoryCode)
    } else {
        directoryStore.resetOriginDirectory()
    }
})