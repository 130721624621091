import {defineStore} from 'pinia'
import type {DirectoryData} from '~/types/DirectoryData'
import {useUserStore} from '~/stores/user'
import {DIRECTORY_SLIDESHOW_DIALOG_CLOSED, DIRECTORY_SLIDESHOW_DIALOG_REQUESTED} from '~/types/bus-event-names'

export const useDirectoryStore = defineStore('directory', () => {

    const emitter = useNuxtApp().$mitt

    const originDirectory = ref<DirectoryData|null>(null)
    const userStore = useUserStore()
    const originDirectories = ref<{[key: string]: DirectoryData}>({})
    const currentDirectory = ref<DirectoryData|null>(null)
    const currentDirectorySlideshowPosition = ref<null|number>(null)

    const originDirectoryCode = computed<string|null>(() => originDirectory.value ? originDirectory.value.code : null)
    const directories = computed<DirectoryData[]>(() => {
        return useUserStore().userData.directories || []
    })

    watch(() => userStore.userId, () => {
        // Clear store on sign in/out
        originDirectories.value = {}
    })

    const directoryAllowsCapture = computed<boolean>(() => {
        if (directories.value.find(directory => directory.code === originDirectoryCode.value)) {
            return true
        }
        return originDirectory.value ? originDirectory.value.isOpenToCaptures : false
    })

    function findDirectoryByCode(code: string): DirectoryData|null {
        let directoryData =  directories.value.find(directory => directory.code === code)
        if (!directoryData) {
            directoryData = originDirectories.value[code]
        }
        return directoryData ? directoryData :  null
    }

    async function getDirectory(code: string, andMakeCurrent = false): Promise<DirectoryData> {
        let directoryData = findDirectoryByCode(code)

        if (!directoryData) {
            const {data, error} = await useApiFetch<DirectoryData>(`/favorite-directory/${code}`)
            if (error.value || !data.value) {
                throw createError({ statusCode: 404, statusMessage: 'Could not find directory.' })
            }
            directoryData = data.value
            originDirectories.value[code] = directoryData
        }

        originDirectory.value = directoryData
        currentDirectory.value = directoryData

        return directoryData
    }

    function setPositions(directoryCode: string, positionsPerId: {[key: number]: number}) {
        const directory = findDirectoryByCode(directoryCode)

        if (!directory) {
            throw new Error('Cannot set positions of unknown directory')
        }

        directory.favorites.forEach(favorite => {
            if (positionsPerId[favorite.id] !== undefined) {
                favorite.position = positionsPerId[favorite.id]
            }
        })

        const fd = new FormData()
        fd.append('positions', JSON.stringify(positionsPerId))

        useBaseApiFetch(`/favorite-directory/update-positions/${directoryCode}`, {method: 'post', body: fd})
    }

    emitter.on(DIRECTORY_SLIDESHOW_DIALOG_CLOSED, () => {
        currentDirectorySlideshowPosition.value = null
    })

    watch(currentDirectorySlideshowPosition, (position, oldPosition) => {
        if (position !== null && oldPosition === null) {
            emitter.emit(DIRECTORY_SLIDESHOW_DIALOG_REQUESTED)
            return
        }
    })

    function slideshowNext() {
        if (currentDirectorySlideshowPosition.value === null) {
            return
        }

        currentDirectorySlideshowPosition.value = Math.min(currentDirectorySlideshowPosition.value + 1, currentDirectory.value!.favorites.length - 1)
    }

    function slideshowPrevious() {
        if (currentDirectorySlideshowPosition.value === null) {
            return
        }

        currentDirectorySlideshowPosition.value = Math.max(currentDirectorySlideshowPosition.value - 1, 0)
    }

    function resetOriginDirectory() {
        originDirectory.value = null
    }

    return {
        getDirectory,
        resetOriginDirectory,
        setPositions,

        directoryAllowsCapture,
        originDirectoryCode,
        currentDirectory,
        currentDirectorySlideshowPosition,
        slideshowNext,
        slideshowPrevious,
    }
})