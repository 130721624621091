import Toastify from 'toastify-js'
import {defineNuxtPlugin} from "#app"
import 'toastify-js/src/toastify.css'
import '../assets/css/toastify.css'

// This plugin is only used on the client side
// A wrapper composable calls the methods after checking they are available
// to prevent server-side errors

function showToast(text: string, options = {}) {
    Toastify({
        text,
        gravity: 'bottom',
        position: 'center',
        duration: 2000,
        selector: document.querySelector('dialog[open]') ?? document.body,
        ...options,
    }).showToast()
}

function showSuccessToast(text: string) {
    showToast(text, {
        style: {
            background: '#00A651'
        },
    })
}

function showWarningToast(text: string) {
    showToast(text, {
        style: {
            background: '#F7941D'
        },
    })
}

function showErrorToast(text: string) {
    showToast(text, {
        style: {
            background: '#ED1B2F'
        },
    })
}

export default defineNuxtPlugin(() => {
    return {
        provide: {
            showToast,
            showSuccessToast,
            showWarningToast,
            showErrorToast,
        }
    }
})
