import {defineStore} from "pinia"
import {ref} from "vue"
import type {I18nData} from '~/types/I18nData'
import type {WithI18nData} from '~/types/WithI18nData'

export const useCurrentPageRouteI18nStore = defineStore('current-page-route-i18n', () => {
    const baseName = ref<String|null>(null)
    const params = ref({})
    const i18nParams = ref<I18nData>({})

    function getCurrentLocalPageObject(locale: string) {
        const routeBaseName = useNuxtApp().$getRouteBaseName()

        if (routeBaseName === 'index') {
            return { name: routeBaseName }
        }

        let routeParams = params.value
        if (i18nParams.value && i18nParams.value[locale]) {
            routeParams = { ...routeParams, ...i18nParams.value[locale] }
        } else {
            return { name: 'index' }
        }

        return {
            name: routeBaseName,
            params: routeParams
        }
    }

    function storeRouteI18nData(d: WithI18nData|null) {
        if (!d || !d.i18n) {
            return
        }

        i18nParams.value = d.i18n
    }

    return {
        baseName,
        params,
        i18nParams,

        getCurrentLocalPageObject,
        storeRouteI18nData,
    }
})