import {useDomainDataStore} from "~/stores/domain"
import {useUserStore} from "~/stores/user"
import {RouteLocationNormalized} from "vue-router";

const { objectToFormData } = useFormData()

export default defineNuxtRouteMiddleware((to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const domainStore = useDomainDataStore()
    const url = domainStore.getDomainHome() + to.fullPath

    if (useUserStore().isAuthenticated) {
        useBaseApiFetch('/events/page', {method: 'post', body: objectToFormData({url})})
    }
})