<script setup lang="ts">
import {useDomainDataStore} from "~/stores/domain"

const { $i18n } = useNuxtApp()
const {domainData} = useDomainDataStore()

useHead({
  titleTemplate: (titleChunk) => {
    return titleChunk ? `${titleChunk} – EduMedia` : 'EduMedia'
  },
  htmlAttrs: {
    lang: $i18n.locale.value,
  },
  bodyAttrs: {
    class: `domain-${domainData.domain} overflow-x-hidden`,
  },
  link: () => {
    return [
      {rel: 'icon', href: `/assets/favicon/${domainData.domain}/icon.svg`, type: 'image/svg+xml'},
      {rel: 'apple-touch-icon', href: `/assets/favicon/${domainData.domain}/icon-180.png`},
      {rel: 'manifest', href: `/assets/favicon/${domainData.domain}/manifest.webmanifest`},
    ]
  }
})

const metaTitle = () => $i18n.t('company.tagline')
const socialTitle = () => `EduMedia – ${$i18n.t('company.tagline')}`
const metaDescription = () => $i18n.t('meta.description')

useSeoMeta({
  title: metaTitle,
  ogTitle: socialTitle,
  twitterTitle: socialTitle,

  description: metaDescription,
  ogDescription:  metaDescription,
  twitterDescription: metaDescription,

  ogImage: '/assets/images/meta-thumbnail.png',
  twitterImage: '/assets/images/meta-thumbnail.png',
  twitterCard: 'summary_large_image',
})
</script>

<template>
  <NuxtLayout>
    <NuxtPage/>
  </NuxtLayout>
  <MoleculesDialogTeacherAccountRequiredDialog/>
</template>
